<template>
<div :qid="question.id">
    <div v-if="debug" style="font-weight: bold; color: red;" v-html="question.id"/>
    <div v-html="question.header.text.en"/>
    <!-- <Tooltip v-if="infoTrigger && infoTrigger.triggers.body" :trigger="infoTrigger.triggers.body" :content="infoTrigger.content"/> -->
    <div v-html="question.body.text.en"/>
    <div style="margin: 5px; font-size: 0.8em;" v-html="question.subtext.text.en"/>
    <v-list dense>
        <v-container :disabled="true" :value="true" v-for="group in question.groups" :key="'qid_'+question.id+'_gid_'+group.id" style="">
            <v-row>
                <v-col>
                    <!-- <template v-slot:activator> -->
                        <!-- <v-list-item-title>
                            {{group.text.en}}
                        </v-list-item-title> -->
                    <!-- </template> -->

                    <template v-if="group.type=='number'">
                        <v-list-item-title>
                            {{group.text}}
                        </v-list-item-title>
                        <v-list-item-content>
                            <v-text-field v-if="form.group[group.id].value"/>
                        </v-list-item-content>
                    </template>

                    <template v-else-if="group.type=='radio'">
                        <v-list-item-title>
                            {{group.text.en}}
                        </v-list-item-title>
                        <v-radio-group v-model="form.group[group.id].value">
                            <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                                <v-list-item-content>
                                    <v-radio dense :value="choice.value ? choice.value : choice.id" :label="choice.text.en"/>
                                    <v-text-field v-if="form.group[group.id].value==choice.value && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-radio-group>
                    </template>

                    <template v-else-if="group.type=='checkbox'">
                        <v-list-item-title>
                            {{group.text.en}}
                        </v-list-item-title>
                        <template v-for="(choice) in group.choices">
                            <!-- <v-list-item v-if="group.dividers && group.dividers[choice_index]" :key="'qid_'+question.id+'_gid_'+group.id+'_c_divider'+choice_index">
                                <v-list-item-title v-html="group.dividers[choice_index].en"/>
                            </v-list-item> -->
                            <v-list-item :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                                <v-list-item-content>
                                    <!-- <v-checkbox class="shrink mr-0 mt-0" dense v-model="form.group[group.id].value" :label="choice.text.en" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)"/> -->

                                    <v-container :dense="true" :no-gutters="true" style="padding: 0px;">
                                        <v-row>
                                            <v-col>
                                                <v-checkbox dense v-model="form.group[group.id].value" :label="choice.text.en" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)"/>
                                                <v-text-field v-if="form.group[group.id].value.includes(choice.value) && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                                            </v-col>
                                            <v-col v-if="choice.side" align-self="start">
                                                <div v-show="form.group[group.id].value.includes(choice.value)" v-html="choice.side.en"/>
                                            </v-col>
                                        </v-row>
                                    </v-container>                            
                                    
                                </v-list-item-content>
                                <!-- <v-list-item-content>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-checkbox dense v-model="form.group[group.id].value" :label="choice.text.en" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)"/>
                                                <v-text-field v-if="form.group[group.id].value.includes(choice.value) && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                                            </v-col>
                                            <v-col v-if="choice.side" align-self="start">
                                                <div v-html="choice.side.en"/>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-list-item-content> -->
                            </v-list-item>
                        </template>
                    </template>

                    <template v-else>
                        Unknown group type: {{group.type}}
                    </template>
                </v-col>


                <v-col v-if="group.groupSide">
                    <template v-for="(sideItem, itemIndex) in group.groupSide">
                        <div v-show="form.group[group.id].value.filter(choice => sideItem.triggerChoices.includes(choice)).length>0" :key="'qid_'+question.id+'_gid_'+group.id+'_groupSide_'+itemIndex" v-html="sideItem.content.en"/>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-list>

    <v-dialog v-model="ui.info.display" width="50%">
        <v-card>
            <v-card-title/>
            <v-card-text>
                <div :qid="question.id" v-html="ui.info.content"/>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                    <v-btn @click="ui.info.display=false">close</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
// import Tooltip from './Tooltip'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        info: {
            type: Object,
            required: false,
            default: null
        },
        images: {
            type: Object,
            required: false,
            default: null
        }
    },    
    components: {
        // Tooltip
    },
    created: function(){
        let form = this.form
        let question = this.question
        for(let g=0; g<question.groups.length; g++){
            let group = question.groups[g]

            switch(group.type){
                default:
                    this.$set(form.group, group.id, {
                        value: null,
                        others: {}
                    })
                break;

                case"checkbox":
                    this.$set(form.group, group.id, {
                        value: [],
                        others: {}
                    })
                break;

            }
        }
    },
    mounted: function(){
        let self = this
        let iconClasses = [
            'v-icon',
            'notranslate',
            'mdi',
            'mdi-information-outline',
            'theme--light'
        ]
        let icons = document.querySelectorAll("[qid='"+this.question.id+"'] [ui-icon='info']")
        for(let i=0; i<icons.length; i++){
            let elem = icons[i]
            let target = elem.getAttribute('info-target')
            for(let c=0; c<iconClasses.length; c++){
                elem.classList.add(iconClasses[c])
                elem.removeEventListener("click",()=>{self.infoClick(target)})
                elem.addEventListener("click",()=>{self.infoClick(target)})
            }
        }

        let links = document.querySelectorAll("[qid='"+this.question.id+"'] [ui-link='info']")
        for(let i=0; i<links.length; i++){
            let elem = links[i]
            let target = elem.getAttribute('info-target')
            elem.removeEventListener("click",()=>{self.infoClick(target)})
            elem.addEventListener("click",()=>{self.infoClick(target)})
        }

        self.linkImages()
    },
    data: function(){
        return {
            form: {
                group: {}
            },
            clicked: {
                group: null,
                choice: null,
                checked: null
            },
            ui: {
                info: {
                    display: false,
                    content: null
                }
            }
        }
    },
    methods: {
        infoClick: function(target){
            let self = this
            let info = this.info
            if(info[target]){
                this.ui.info.content = info[target].content.en
                this.ui.info.display = !this.ui.info.display
            }

            setTimeout(function(){
                self.linkImages()
            },800)            
        },
        linkImages: function(){
            let self = this
            let imageElems = document.querySelectorAll("[qid='"+this.question.id+"'] img")
            for(let i=0; i<imageElems.length; i++){
                let elem = imageElems[i]
                let target = elem.getAttribute('src')
                let src = self.images[target]

                if(src){
                    elem.src = self.images[target]
                }
            }            
        },
        checkboxHandler: function(group, choice){
            this.clicked.group = group
            this.clicked.choice = choice
            this.clicked.checked = false

            if(this.form.group[group.id].value!=null && this.form.group[group.id].value.includes(choice.value)){
                if(choice.exclusive){
                    this.form.group[group.id].value = [choice.value]
                }else{
                    let exclusives = []
                    let filtered = []
                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        if(choice.exclusive){
                            exclusives.push(choice.value)
                        }
                    }
                    for(let v=0; v<this.form.group[group.id].value.length; v++){
                        let value = this.form.group[group.id].value[v]
                        if(!exclusives.includes(value)){
                            filtered.push(value)
                        }
                    }
                    this.form.group[group.id].value = filtered
                }

                this.clicked.checked = this.form.group[group.id].value.includes(choice.value)
            }
        }
    },
    // computed: {
    //     infoTrigger: function(){
    //         let question = this.question
    //         let info = this.info
    //         if(info){
    //             for(let key in info){
    //                 let infoItem = info[key]
    //                 if(infoItem.triggers[question.id]){
    //                     return {triggers: infoItem.triggers[question.id], content: infoItem.content.en}
    //                 }
    //             }
    //         }
    //         return null
    //     }
    // },
    watch: {
        form: {
            deep: true,
            handler: function(){
                let form = this.form
                let question = this.question
                let clicked = this.clicked
                this.$emit('input',form)
                this.$emit('questionUpdate', {question, form, clicked})
            }
        }
    }
}
</script>

<style>

</style>